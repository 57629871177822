import EmberRouter from '@ember/routing/router';
import config from 'bi/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('report', { path: '/report/:report_id' }, function () {
    this.route('visual', { path: '/visual/:visual_id' });
  });

  //this.route('queryeditor');
  this.route('utility');

  this.route('headless', { path: '/headless/:headless_id' });
  // this.route('utility');
  this.route('print', { path: '/print/:print_id' });
  this.route('wallboards');
  this.route('wallboardconfig', { path: '/wallboards/:wallboardid' });
  this.route('wallboard', { path: '/wallboard/:key' });
  this.route('wallboardreport', {
    path: '/wallboard/:key/report/:reportIndex',
  });
  this.route('reportsummary');
  this.route('scratchpad');
  this.route('home', { path: '/' });
  // this.route('shares');
});
