import ApplicationAdapter from './application';

export default class ReportAdapter extends ApplicationAdapter {
  urlForQuery(query, modelName) {
    if (query.reportType) {
      let url = '';
      switch (query.reportType) {
        case 'shared':
          url = `${this.buildURL()}/reports/shared`;
          break;
        // This code wouldn't actually work, it's just an example for how we could handle fetching different
        // report types from different endpoints but maintain a single source of truth data store for reports.
        //
        // case 'standard':
        //   url = `${this.buildURL()}/report/standard`;
        //   break;
        // case 'personal':
        //   url = `${this.buildURL()}/report/me`;
        //   break;
        default:
          url = super.urlForQuery(query, modelName);
      }
      delete query.reportType;
      return url;
    }

    return super.urlForQuery(query, modelName);
  }
}
