import Service from '@ember/service';
import { inject as service } from '@ember/service';

// TODO: I don't know that this service is necessary, it would be easy enough to just use the store directly
// Howevber, the getReportsByType could be usefull if we ever need that

export default class ReportsV2Service extends Service {
  @service store;

  async fetchSharedReports() {
    return this.store.query('report', {
      reportType: 'shared',
    });
  }

  async fetchPersonalReports() {
    return this.store.query('report', {
      reportType: 'owned',
    });
  }

  async fetchStandardReports() {
    return this.store.query('report', {
      reportType: 'standard',
    });
  }

  // Helper methods to filter reports from the store
  getReportsByType(type) {
    return this.store
      .peekAll('report')
      .filter((report) => report.sourceTypes.includes(type));
  }
}
