import Model, { attr, hasMany } from '@ember-data/model';

export default class ReportModel extends Model {
  @attr('string') name;
  @attr('string') notes;
  @attr('string') folder;
  @attr('string') userId;
  @attr('number') reportVersion;
  @attr('object') reportAccessLevel;
  @attr('object') visuals;
  @attr('object') dataSources;
  @attr('array', { transient: true }) sourceTypes;
}
