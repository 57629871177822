import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SharesController extends Controller {
  @tracked reportSearchQuery = '';
  @tracked userSearchQuery = '';
  // @tracked selectedReports = new Set();

  get filteredReports() {
    if (!this.reportSearchQuery) {
      return this.model;
    }

    const query = this.reportSearchQuery.toLowerCase();
    return this.model.filter((report) => {
      const name = report.get('name')?.toLowerCase() || '';
      const folder = report.get('folder')?.toLowerCase() || '';
      const matches = name.includes(query) || folder.includes(query);
      return matches;
    });
  }

  get totalReports() {
    return this.model.meta?.total;
  }

  @action
  updateReportSearch(event) {
    this.reportSearchQuery = event.target.value;
  }

  @action
  updateUserSearch(event) {
    this.userSearchQuery = event.target.value;
  }
}
