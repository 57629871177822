import Transform from '@ember-data/serializer/transform';

export default class ArrayTransform extends Transform {
  deserialize(serialized) {
    if (Array.isArray(serialized)) {
      return serialized;
    }
    return serialized ? serialized.split(',') : [];
  }

  serialize(deserialized) {
    if (Array.isArray(deserialized)) {
      return deserialized.join(',');
    }
    return deserialized || '';
  }
}
