import ApplicationSerializer from './application';

export default class ReportSerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    // Process reports array if present
    if (payload.reports) {
      payload.reports = payload.reports.map((report) => {
        const sourceTypes = [];

        // Check access level for owner and shared types
        if (report.reportAccessLevel?.isOwner) {
          sourceTypes.push('owner');
        } else if (
          report.reportAccessLevel?.canEdit ||
          report.reportAccessLevel?.canRead
        ) {
          sourceTypes.push('shared');
        }

        // Check meta source for shareable type
        if (payload.meta?.source === '/reports/shared') {
          sourceTypes.push('shareable');
        }

        return {
          ...report,
          sourceTypes,
        };
      });
    }

    return super.normalizeResponse(...arguments);
  }
}
