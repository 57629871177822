import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SharesRoute extends Route {
  @service reportsv2;

  async model() {
    return this.reportsv2.fetchSharedReports();
  }
}
